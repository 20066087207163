<template>
	<div class="cs-video-block cs-block" :class="`${styleClass}${hasBg}`">
		<div class="cs-block-base">

			<div class="container">

				<div class="header-content">
                    <h2 class="cs-title" v-if="data.title">
                        <ColorSplit :data="data.title" />
                    </h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">
                        {{ data.subTitle }}
                    </h3>
                    <span v-if="data.text" class="cs-text" v-html="data.text"/>
                </div>

				<div class="video-wrapper">
					<div :class="overlayClasses" @click="playVideo">
						<button class="revert">
							<svg width="18" height="20" viewBox="0 0 14 16" fill="white" xmlns="http://www.w3.org/2000/svg">
								<path d="M14 8L0.499999 15.7942L0.5 0.205771L14 8Z"/>
							</svg>
						</button>
					</div>
					<video ref="videoblock" :controls="videoPlayedOnce">
						<source :src="data.video" />
					</video>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import {computed} from '../libs/common-fn';

export default {
		name: 'Video',
		components: {
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
        },
		props: {
			data: {
				type: Object,
			default: () => {},
			},
		},
		data() {
			return {
				videoPlayedOnce: false
			}
		},
		computed: {
            ...computed('Video'),
            hasBg() {
                if (this.data.style == 'color') return ' has-background';

                return '';
            }
        },
		methods: {
			playVideo() {
				this.videoPlayedOnce = true;
				this.$refs.videoblock.play();
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '../styles/main.scss';

	@mixin cs-video-height {
		height: 600px;

		@media (max-width: 991.98px) { 
			height: 320px;
		}

		@media (max-width: 575.98px) {
			height: 180;
		}
	}
	.cs-video-block {
		
		&.cs-style- {
			&color {
				@include cs-block-color;
				.cs-title {
                    color: white
                }
                .cs-sub-title {
                    color: $sub_title_color_light;
                }

                .cs-text {
                    color: $text_color_over_theme_color;

                    ::v-deep {
                        * {
                            color: $text_color_over_theme_color !important;
                        }
                    }
                }
			}

			&light {
				@include cs-block-light;
			}

			&dark {
				@include cs-block-dark;
			}
		}
        .header-content {
            text-align: center;
            margin-bottom: $header_margin;
            &:last-child {
                margin-bottom: 0;
            }
            .cs-title, .cs-sub-title {
                margin-bottom: 20px;
            }
            .cs-text {
                ::v-deep {
                    * {
                        text-align: center;
                    }
                    p,
                    span {
                        @include text;
                    }
                    *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .cs-block-base {
            width: 100%;
            position: relative;

            video {
                @include cs-video-height;
                border-radius: 15px;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
            .video-wrapper{
                position: relative;
                height: 100%;
                width: 100%;
                border: 1px solid #7d98b5;
                -webkit-backdrop-filter: blur(25px);
                backdrop-filter: blur(25px);
                background: #ffffff57;
                border-radius: 15px;
                padding: 7px 7px 0px 7px;

                .cs-video-overlay {
                    @include cs-video-height;
                    width: 100%;
                    position: absolute;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 300ms ease-in-out;

                    &.video-overlay-hidden {
                        filter: alpha(opacity=0);
                        opacity: 0;
                        pointer-events: none;
                    }

                    button {
                        background: linear-gradient(90deg, rgba(255,226,190,1) 35%, rgba(254,179,50,1) 100%);
                        display: flex;
                        box-shadow: 0px 0px 0px 8px #f7c46d87;
                        border: none;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        width: 90px;
                        height: 90px;
                        position: absolute;
                        &:hover{
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
	}
</style>